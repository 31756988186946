import { create } from "@storybook/theming/create";

export default create({
  base: "light",
  fontBase: "var(--font-family)",
  // fontCode: "monospace",

  brandTitle: "ACP Design System",
  brandUrl: "https://example.com",
  brandImage: "https://customer-api.cuportaldev.cubido.at/img/logo.png",
  brandTarget: "_self",

  colorPrimary: "#3d4a52",
  colorSecondary: "#f00f40",

  appBg: "#FFFFFF",
  appContentBg: "#FFFFFF",
  //appBorderColor: "#3d4a52",
  appBorderRadius: 4,

  //   textColor: '#10162F',
  //   textInverseColor: '#ffffff',

  barTextColor: "#3a4b52",
  barSelectedColor: "#585C6D",
  barBg: "#ffffff",

  //   inputBg: '#ffffff',
  //   inputBorder: '#10162F',
  //   inputTextColor: '#3a4b52',
  //   inputBorderRadius: 2,
});
